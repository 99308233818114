























































import { computed, defineComponent, PropType } from "@nuxtjs/composition-api";
import { SfTabs } from "@storefront-ui/vue";
import ProductAddReviewForm from "~/modules/catalog/product/components/ProductAddReviewForm.vue";
import HTMLContent from "~/components/HTMLContent.vue";
import { Product } from "~/modules/catalog/product/types";
import { TabsConfig } from "~/modules/catalog/product/composables/useProductTabs";
import ProductSpecifications from "~/components/ProductSpecifications.vue";

export default defineComponent({
  name: "ProductTabs",
  components: {
    ProductAddReviewForm,
    HTMLContent,
    SfTabs,
    ProductSpecifications,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product | null>,
      default: null,
    },
    openTab: {
      type: Number,
      default: TabsConfig.description.ID,
    },
  },
  setup(props, { emit }) {
    const changeTab = (tabNumber: number) => {
      if (!process.client) return props.openTab;
      const tabs = document.querySelector("#tabs");

      /* if (!tabs) return props.openTab;
      tabs.scrollIntoView({
        block: "start",
        behavior: "smooth",
      }); */

      emit("changeTab", tabNumber);
      return tabNumber;
    };

    const activeTab = computed(() => changeTab(props.openTab));

    const productDescription = computed(
      () => props.product?.description?.html || ""
    );

    const productCleaning = computed(
      () => props.product?.washing___cleaning_instructions || ""
    );

    const productWarnings = computed(() => props.product?.warnings || "");

    return {
      activeTab,
      changeTab,
      productDescription,
      productCleaning,
      productWarnings,
    };
  },
});
