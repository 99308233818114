<template>
  <div>
    <div v-if="storyBlokData">
      <section
        v-for="(blok, index) in storyBlokData.body"
        :key="index + 'blok'"
      >
        <component
          v-if="blok.component"
          :key="blok._uid"
          :blok="blok"
          :is="blok.component"
        />
      </section>
    </div>
  </div>
</template>

<script>
import {
  ref,
  useFetch,
  useContext,
  useRoute,
  computed,
} from "@nuxtjs/composition-api";
import { getMetaInfo } from "~/helpers/getMetaInfo";

export default {
  setup() {
    const { app, error } = useContext();
    const route = useRoute();
    const storyBlokData = ref(null);

    const baseUrl = app.$config.baseUrl;
    const locale = route.value.fullPath;

    const metatags = computed(() => storyBlokData?.value?.metatags);

    useFetch(async () => {
      await app.$storyblok(route.value.params.slug).then((res) => {
        if (res.story) {
          storyBlokData.value = res.story.content;
        } else {
          error({ statusCode: 404 });
        }
      });
    });
    return {
      storyBlokData,
      metatags,
      baseUrl,
      locale,
    };
  },
  head() {
    return getMetaInfo(this.metatags, this.locale, this.baseUrl);
  },
};
</script>
