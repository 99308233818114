import { ref } from '@nuxtjs/composition-api';
import type { TabsConfigInterface } from '~/modules/catalog/product/composables/useProductTabs/useProductTabs';
import type { UseProductTabsInterface } from '~/modules/catalog/product/composables/useProductTabs';

export const TabsConfig: TabsConfigInterface = {
  description: {
    ID: 1,
    title: 'Description',
  },
  package_content: {
    ID: 2,
    title: 'Package Content',
  },
  specifications: {
    ID: 3,
    title: 'Specifications',
  },
  support: {
    ID: 4,
    title: 'Support',
  },
};

/**
 * The `useProductTabs()` composable allows manage product's tabs
 *
 * See the {@link UseProductTabsInterface} page for more information.
 */
export function useProductTabs(): UseProductTabsInterface {
  const activeTab = ref(TabsConfig.description.ID);

  const setActiveTab = (tabId: number) => {
    activeTab.value = tabId;
    
    /* const tabs = document.querySelector("#tabs");

    tabs.scrollIntoView({
      block: "start",
      behavior: "smooth",
    }); */
   
  };

  return {
    activeTab,
    setActiveTab,
  };
}

export default useProductTabs;
export * from './useProductTabs';
